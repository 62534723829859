import WebSocketEvent from './types/webSocketEvent';
import webworker from '../Modules/util/webworker';

type SubscriptionCallback = (data?: Record<string, any>) => void;

const subscriptions: { [key: string]: Array<SubscriptionCallback> } = {};
let subscribed = false;

function initialize(): void {
    webworker.addMessageListener('wsMessage', ([eventData]) => {
        const { eventIdentifier, data } = JSON.parse(eventData) as WebSocketEvent;
        console.log('incoming', eventIdentifier, subscriptions[eventIdentifier]);
        if (subscriptions[eventIdentifier]) {
            subscriptions[eventIdentifier].forEach(callback => {
                callback(data);
            });
        }
    });
}

function subscribe(eventIdentifier: string, callback: SubscriptionCallback): void {
    if (!subscriptions[eventIdentifier]) {
        subscriptions[eventIdentifier] = [];
    }
    subscriptions[eventIdentifier].push(callback);

    if (!subscribed) {
        webworker.sendMessage('wsSubscribe', []);
        subscribed = true;
    }
}

function unsubscribe(eventIdentifier: string, callback: SubscriptionCallback): void {
    function noSubscriptionsLeft() {
        return Object.values(subscriptions).reduce((prev, subscription) => prev && subscription.length === 0, true);
    }

    if (!subscriptions[eventIdentifier]) {
        return;
    }
    subscriptions[eventIdentifier] = subscriptions[eventIdentifier].filter(cb => cb !== callback);

    if (subscribed && noSubscriptionsLeft()) {
        webworker.sendMessage('wsUnsubscribe', []);
        subscribed = false;
    }
}

export default {
    initialize,
    subscribe,
    unsubscribe,
};
