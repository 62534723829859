import { SnackbarProvider, SnackbarMessage, OptionsObject, SnackbarKey } from 'notistack';
import languageUtil from '../../Modules/util/language/react';
import enLocale from './locale/en.json';
import heLocale from './locale/he.json';

type EnqueueProps = (message: SnackbarMessage, options?: OptionsObject) => SnackbarKey;
type PopUpProps = {
    SnackbarProvider: typeof SnackbarProvider;
    handleNotification: (
        variant: 'error' | 'success' | 'warning' | 'info' | 'default',

        enqueuefunction: EnqueueProps,
        text?: string,
        hideDuration?: number,
        position?: { vertical: 'top' | 'bottom'; horizontal: 'left' | 'right' | 'center' }
    ) => void;
};

const locales: Record<string, Record<string, string>> = {
    en: enLocale,
    he: heLocale,
};
export const usePopUpNotification = (): PopUpProps => {
    const t = languageUtil.useTranslationNamespace('toast', language => Promise.resolve(locales[language])) as (
        string: string
    ) => string;

    const handleNotification = (
        variant: 'error' | 'success' | 'warning' | 'info' | 'default',
        enqueuefunction: EnqueueProps,
        text?: string,
        hideDuration?: number,
        position?: { vertical: 'top' | 'bottom'; horizontal: 'left' | 'right' | 'center' }
    ) => {
        enqueuefunction(text || t(variant), {
            variant,
            autoHideDuration: hideDuration || 3000,
            anchorOrigin: position,
        });
    };

    return { SnackbarProvider, handleNotification };
};
