import { useEffect } from "react";

declare const Tawk_API: {
  onLoad: () => void;
  isChatHidden: () => boolean;
  hideWidget: () => void;
  showWidget: () => void;
};

type Context = {
  isMobile: boolean;
  isPrinting: boolean;
};

const globalContext: Context = {
  isMobile: false,
  isPrinting: false,
};

function updateWidget(context: Context = globalContext) {
  try {
    if (
      !Tawk_API ||
      !Tawk_API.isChatHidden ||
      !Tawk_API.hideWidget ||
      !Tawk_API.showWidget
    ) {
      return;
    }
  } catch (_) {
    // For some reason 'Tawk_API is undefined' exception is thrown despite the guard
    return;
  }

  const shouldBeHidden = context.isMobile || context.isPrinting;
  if (shouldBeHidden && !Tawk_API.isChatHidden()) {
    Tawk_API.hideWidget();
  } else if (!shouldBeHidden && Tawk_API.isChatHidden()) {
    Tawk_API.showWidget();
  }
}

window.addEventListener("load", () => {
  try {
    if (!Tawk_API) {
      return;
    }
  } catch (_) {
    // For some reason 'Tawk_API is undefined' exception is thrown despite the guard
    return;
  }

  Tawk_API.onLoad = updateWidget;
});

function HideWhenPrint(context: Context = globalContext): void {
  useEffect(() => {
    const handleBeforePrint = () => {
      context.isPrinting = true;
      updateWidget(context);
      setTimeout(() => {
        context.isPrinting = false;
        updateWidget(context);
      }, 1000);
    };
    window.addEventListener("beforeprint", handleBeforePrint);
    return () => {
      window.removeEventListener("beforeprint", handleBeforePrint);
    };
  }, []);
}

function HideWhenMobile(context: Context = globalContext): void {
  useEffect(() => {
    const handleResize = () => {
      const widgetWidth = 64;
      const widgetMargin = 20;
      const contentWidth = 480;
      const contentPadding = 24;
      if (
        window.innerWidth <
        contentWidth + contentPadding + widgetWidth + widgetMargin
      ) {
        context.isMobile = true;
        updateWidget(context);
      } else {
        context.isMobile = false;
        updateWidget(context);
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
}

function hideWidget(): void {
  try {
    if (!Tawk_API || !Tawk_API.hideWidget) {
      return;
    }
  } catch (_) {
    // For some reason 'Tawk_API is undefined' exception is thrown despite the guard
    return;
  }
  Tawk_API.hideWidget();
}

function showWidget(): void {
  try {
    if (!Tawk_API || !Tawk_API.showWidget) {
      return;
    }
  } catch (_) {
    // For some reason 'Tawk_API is undefined' exception is thrown despite the guard
    return;
  }
  Tawk_API.showWidget();
}

export default {
  HideWhenPrint,
  HideWhenMobile,
  hideWidget,
  showWidget,
};
