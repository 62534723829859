import axios, { AxiosRequestConfig, Method, AxiosResponse } from 'axios';
import apiUrl from '../../Config/apiUrl';
import { IQueue, IProcessedQueue } from '../../types/queue';
import { IQueueCustomer, QueueCustomerLeaveReasons } from '../../types/queueCustomer';
import { IQueueGroup, IQueueGroupType, IQueueGroupCountry } from '../../types/queueGroup';
import { IPermission } from '../../types/queueGroupUserPermission';
import { IUser } from '../../types/user';
import { IAnalytic } from '../../types/analytic';
import { IAdvertisement, ICartItem } from '../../types/advertisement';
import { IQueueArea } from '../../types/queueArea';
import { IReservation, ReservationTraitType } from '../../types/reservation';
import { IQueueGroupTable } from '../../types/queueGroupTable';

type Options = {
    headers?: {
        [key: string]: any;
    };
};

type ApiCallResponse = AxiosResponse;
export const MAPBOX_TOKEN = 'pk.eyJ1Ijoib3BkYXJrIiwiYSI6ImNsY2tlcDV0czFoamUzcHF1OWs2OWZlYjgifQ.IlCCLbHp1g5yh4XIx7RVJw';

function apiCall(method: Method, route: string, data = {}, options: Options = {}): Promise<ApiCallResponse> {
    const authToken = sessionStorage.getItem('authToken');
    const language = localStorage.getItem('preferredLanguage') ? localStorage.getItem('preferredLanguage') : 'he';
    const axiosConfig: AxiosRequestConfig = {
        ...options,
        method: method,
        headers: {
            ...(options.headers || {}),
            Authorization: authToken ? authToken : '', //axios in version 0.21.1 put null objects as 'null' string.
            'Accept-Language': language || 'he',
        },
    };
    if (method === 'get') {
        axiosConfig.params = data;
    } else {
        axiosConfig.data = data;
    }
    return new Promise((resolve, reject) => {
        axios(`${apiUrl.getApiUrl()}/${route}`, axiosConfig)
            .then(response => resolve(response))
            .catch(err => {
                if (err.response) {
                    // is axios error
                    // We check if the error is related specifically to the
                    // auth token. Look in the "authenticated" middleware (on back-end)
                    if (
                        err.response.status === 403 &&
                        (err.response.data === 'not found' || err.response.data === 'inactive')
                    ) {
                        // We check if token hasn't changed since request launch because
                        // we want to invalidate only the token that failed.
                        // If the token changed since request launch, there's no reason
                        // to invalidate the token.
                        if (authToken === sessionStorage.getItem('authToken')) {
                            sessionStorage.removeItem('authToken');
                        }
                    }
                }
                reject(err);
            });
    });
}

const postFeedback = (feedback: { customerId: string; rating: number; feedback: string }): Promise<void> => {
    return apiCall('post', 'feedback', feedback).then(() => {});
};

const getMyQueueGroup = (): Promise<IQueueGroup> => {
    return apiCall('get', 'queueGroup').then(response => response.data as IQueueGroup);
};
const getAllQueueGroup = (): Promise<IQueueGroup[]> => {
    return apiCall('get', 'queueGroup/all').then(response => response.data as IQueueGroup[]);
};

const postQueueGroup = (queueGroup: { name: string }): Promise<void> => {
    return apiCall('post', 'queueGroup', queueGroup).then(() => {});
};

const getQueueGroup = (queueGroupId: string): Promise<IQueueGroup> => {
    return apiCall('get', `queueGroup/${encodeURIComponent(queueGroupId)}`).then(
        response => response.data as IQueueGroup
    );
};

const patchQueueGroup = (queueGroupId: string, updateObj: Partial<IQueueGroup>): Promise<void> => {
    return apiCall('patch', `queueGroup/${encodeURIComponent(queueGroupId)}`, updateObj).then(() => {});
};

const getQueueGroupAreas = (queueGroupId: string): Promise<IQueueArea[]> => {
    return apiCall('get', `queueGroup/${encodeURIComponent(queueGroupId)}/area/all`).then(
        response => response.data as IQueueArea[]
    );
};

const patchQueueGroupArea = (
    queueGroupId: string,
    updateObj: {
        delete: number[];
        add: Partial<IQueueArea>[];
        update: Partial<IQueueArea>[];
    }
): Promise<void> => {
    return apiCall('patch', `queueGroup/${encodeURIComponent(queueGroupId)}/area`, updateObj).then(() => {});
};

const getQueueGroupTables = (queueGroupId: string): Promise<IQueueGroupTable[]> => {
    return apiCall('get', `queueGroup/${encodeURIComponent(queueGroupId)}/table/all`).then(
        response => response.data as IQueueGroupTable[]
    );
};

const patchQueueGroupTable = (
    queueGroupId: string,
    updateObj: {
        delete: number[];
        add: Partial<IQueueGroupTable>[];
        update: Partial<IQueueGroupTable>[];
    }
): Promise<void> => {
    return apiCall('patch', `queueGroup/${encodeURIComponent(queueGroupId)}/table`, updateObj).then(() => {});
};

const putQueueCustomDisplay = (
    queueGroupId: string,
    queueId: string,
    data: { isCustomDisplay: boolean }
): Promise<void> => {
    return apiCall(
        'put',
        `queueGroup/${encodeURIComponent(queueGroupId)}/queue/${encodeURIComponent(queueId)}/display-type`,
        data
    ).then(() => {});
};
const putQueueQRId = (queueGroupId: string, queueId: string, data: { password: string }): Promise<void> => {
    return apiCall(
        'patch',
        `queueGroup/${encodeURIComponent(queueGroupId)}/queue/${encodeURIComponent(queueId)}`,
        data
    ).then(() => {});
};

const putQueueGroupPermission = (queueGroupId: string, permissions: IPermission[]): Promise<void> => {
    return apiCall('put', `queueGroup/${encodeURIComponent(queueGroupId)}/user`, permissions).then(() => {});
};

const postQueueGroupQueue = (queueGroupId: string, queueName: string): Promise<{ id: string }> => {
    return apiCall('post', `queueGroup/${encodeURIComponent(queueGroupId)}/queue`, { name: queueName }).then(
        response => response.data as { id: string }
    );
};

const getAllQueueGroupQueues = (queueGroupId: string): Promise<IQueue[]> => {
    return apiCall('get', `queueGroup/${encodeURIComponent(queueGroupId)}/queue/all`).then(
        response => response.data as IQueue[]
    );
};

const getQueueGroupQueue = (queueGroupId: string, queueId: string, customerId?: string): Promise<IProcessedQueue> => {
    return apiCall('get', `queueGroup/${encodeURIComponent(queueGroupId)}/queue/${encodeURIComponent(queueId)}`, {
        customerId,
    }).then(response => response.data as IProcessedQueue);
};

const deleteQueueGroupQueue = (queueGroupId: string, queueId: string): Promise<void> => {
    return apiCall(
        'delete',
        `queueGroup/${encodeURIComponent(queueGroupId)}/queue/${encodeURIComponent(queueId)}`
    ).then(() => {});
};

const postNextInQueueGroupQueue = (queueGroupId: string, queueId: string): Promise<void> => {
    return apiCall(
        'post',
        `queueGroup/${encodeURIComponent(queueGroupId)}/queue/${encodeURIComponent(queueId)}/next`
    ).then(() => {});
};

const postCallAgainQueueGroupQueue = (queueGroupId: string, queueId: string): Promise<void> => {
    return apiCall(
        'post',
        `queueGroup/${encodeURIComponent(queueGroupId)}/queue/${encodeURIComponent(queueId)}/again`
    ).then(() => {});
};

const postPreviousInQueueGroupQueue = (queueGroupId: string, queueId: string): Promise<void> => {
    return apiCall(
        'post',
        `queueGroup/${encodeURIComponent(queueGroupId)}/queue/${encodeURIComponent(queueId)}/previous`
    ).then(() => {});
};

const postCallCustomer = (queueGroupId: string, queueId: string, queueCustomerId: string): Promise<void> => {
    return apiCall(
        'post',
        `queueGroup/${encodeURIComponent(queueGroupId)}/queue/${encodeURIComponent(
            queueId
        )}/customer/${encodeURIComponent(queueCustomerId)}/call-now`
    ).then(() => {});
};

const deleteNextInQueueGroupQueue = (queueGroupId: string, queueId: string): Promise<void> => {
    return apiCall(
        'delete',
        `queueGroup/${encodeURIComponent(queueGroupId)}/queue/${encodeURIComponent(queueId)}/next`
    ).then(() => {});
};

const putQueueGroupQueueTableCheckout = (
    queueGroupId: string,
    queueId: string,
    table: { tableId: string; peopleCount: number }
): Promise<void> => {
    return apiCall(
        'put',
        `queueGroup/${encodeURIComponent(queueGroupId)}/queue/${encodeURIComponent(queueId)}/table-checkout`,
        table
    ).then(() => {});
};

export type PostedQueueGroupQueueCustomer = {
    customerId: string;
    customerName: string;
    customerPhone: string;
    queueCustomerId: string;
    queueName: string;
    queueCustomers: IQueueCustomer[];
    previousQueueCustomers: IQueueCustomer[];
    queueGroup: IQueueGroup;
    enqueuedNumber: number;
    nowServingNumber: number;
    callTime: Date;
    enqueued: boolean;
    complete: boolean;
};

const postQueueGroupQueueCustomer = (
    queueGroupId: string,
    queueId: string,
    customer: {
        name: string;
        id?: string;
        phone?: string;
        queueCustomer?: Partial<IQueueCustomer>;
    }
): Promise<PostedQueueGroupQueueCustomer> => {
    return apiCall(
        'post',
        `queueGroup/${encodeURIComponent(queueGroupId)}/queue/${encodeURIComponent(queueId)}/customer`,
        customer
    ).then(response => {
        return {
            ...(response.data.data as PostedQueueGroupQueueCustomer),
            enqueued: response.status === 200,
        };
    });
};

const patchQueueGroupQueueCustomer = (
    queueGroupId: string,
    queueId: string,
    customerId: string,
    customer: {
        name: string;
        phone: string;
        queueCustomer?: Partial<IQueueCustomer>;
    }
): Promise<void> => {
    return apiCall(
        'patch',
        `queueGroup/${encodeURIComponent(queueGroupId)}/queue/${encodeURIComponent(
            queueId
        )}/customer/${encodeURIComponent(customerId)}`,
        customer
    ).then(() => {});
};

const patchQueueGroupQueueCustomerNumber = (
    queueGroupId: string,
    queueId: string,
    queueCustomerId: string,
    number: { offset: number }
): Promise<void> => {
    return apiCall(
        'patch',
        `queueGroup/${encodeURIComponent(queueGroupId)}/queue/${encodeURIComponent(
            queueId
        )}/customer/${encodeURIComponent(queueCustomerId)}/number`,
        number
    ).then(() => {});
};

const deleteQueueGroupQueueCustomer = (
    queueGroupId: string,
    queueId: string,
    queueCustomerId: string,
    customerId: string,
    data: { reason: typeof QueueCustomerLeaveReasons[number] }
): Promise<void> => {
    return apiCall(
        'delete',
        `queueGroup/${encodeURIComponent(queueGroupId)}/queue/${encodeURIComponent(
            queueId
        )}/customer/${encodeURIComponent(queueCustomerId)}`,
        { ...data, customerId }
    ).then(() => {});
};

const silentlyDeleteQueueGroupQueueCustomers = (queueGroupId: string, queueId: string): Promise<void> => {
    return apiCall(
        'delete',
        `queueGroup/${encodeURIComponent(queueGroupId)}/queue/${encodeURIComponent(queueId)}/customer/all/silent`
    ).then(() => {});
};

const getRemovedQueueCustomers = (queueGroupId: string, queueId: string): Promise<IQueueCustomer[]> => {
    return apiCall(
        'get',
        `queueGroup/${encodeURIComponent(queueGroupId)}/queue/${encodeURIComponent(
            queueId
        )}/customer/removedQueueCustomers`
    ).then(response => response.data as IQueueCustomer[]);
};

const postQueueGroupQueueCustomerNotifyUsingSms = (
    queueGroupId: string,
    queueId: string,
    queueCustomerId: string,
    customerId: string
): Promise<void> => {
    return apiCall(
        'post',
        `queueGroup/${encodeURIComponent(queueGroupId)}/queue/${encodeURIComponent(
            queueId
        )}/customer/${encodeURIComponent(queueCustomerId)}/notify-using-sms`,
        { customerId }
    ).then(() => {});
};

const getPushNotificationParameters = (): Promise<{
    vapidPublicKey: string;
}> => {
    return apiCall('get', 'notification/parameters').then(response => response.data as { vapidPublicKey: string });
};

const postQueueGroupQueueCustomerNotifyUsingPushNotification = (
    queueGroupId: string,
    queueId: string,
    queueCustomerId: string,
    customerId: string,
    vapid: { endpoint: string; keys: { p256dh: string; auth: string } }
): Promise<void> => {
    return apiCall(
        'post',
        `queueGroup/${encodeURIComponent(queueGroupId)}/queue/${encodeURIComponent(
            queueId
        )}/customer/${encodeURIComponent(queueCustomerId)}/notify-using-push-notification`,
        {
            customerId,
            endpoint: vapid.endpoint,
            keys: {
                p256dh: vapid.keys.p256dh,
                auth: vapid.keys.auth,
            },
        }
    ).then(() => {});
};

const postQueueGroupQueueCustomerRestore = (
    queueGroupId: string,
    queueId: string,
    queueCustomerId: string,
    customerId: string
): Promise<PostedQueueGroupQueueCustomer> => {
    return apiCall(
        'post',
        `queueGroup/${encodeURIComponent(queueGroupId)}/queue/${encodeURIComponent(queueId)}/customer/restore`,
        { queueCustomerId, customerId }
    ).then(response => response.data as PostedQueueGroupQueueCustomer);
};

const putQueueGroupQueueCustomerConfirmed = (
    queueGroupId: string,
    queueId: string,
    queueCustomerId: string,
    customerId: string
): Promise<void> => {
    return apiCall(
        'put',
        `queueGroup/${encodeURIComponent(queueGroupId)}/queue/${encodeURIComponent(
            queueId
        )}/customer/${encodeURIComponent(queueCustomerId)}/confirmed`,
        { customerId }
    ).then(() => {});
};
const postCustomerItemsToCart = (
    queueGroupId: string,
    queueId: string,
    product: IAdvertisement[],
    totalPrice: number,
    orderPlaced: boolean,
    queueCustomerId: string,
    customerId: string
): Promise<void> => {
    return apiCall(
        'post',
        `queueGroup/${encodeURIComponent(queueGroupId)}/queue/${encodeURIComponent(
            queueId
        )}/customer/${encodeURIComponent(queueCustomerId)}/cartItems`,
        { product, totalPrice, orderPlaced }
    ).then(() => {});
};
const getCustomerItemsToCart = (
    queueGroupId: string,
    queueId: string,

    queueCustomerId: string
): Promise<ICartItem[]> => {
    return apiCall(
        'get',
        `queueGroup/${encodeURIComponent(queueGroupId)}/queue/${encodeURIComponent(
            queueId
        )}/customer/${encodeURIComponent(queueCustomerId)}/cartItems`
    ).then(response => response.data as ICartItem[]);
};

const postQueueGroupReservation = (
    queueGroupId: string,
    reservation: IReservation & {
        customer: {
            name: string;
            phone: string;
        };
        traits: ReservationTraitType[];
        areas: number[];
        tables: number[];
    }
): Promise<void> => {
    return apiCall('post', `queueGroup/${encodeURIComponent(queueGroupId)}/reservation`, { reservation }).then(
        () => {}
    );
};

const getAllQueueGroupReservations = (queueGroupId: string): Promise<IReservation[]> => {
    return apiCall('get', `queueGroup/${encodeURIComponent(queueGroupId)}/reservation/all`).then(
        response => response.data as IReservation[]
    );
};

const postQueueGroupAdvertisement = (queueGroupId: string, advertisement: IAdvertisement): Promise<void> => {
    return apiCall('post', `queueGroup/${encodeURIComponent(queueGroupId)}/advertisement`, advertisement).then(
        () => {}
    );
};

const getRandomQueueGroupAdvertisement = (queueGroupId: string): Promise<IAdvertisement> => {
    return apiCall('get', `queueGroup/${encodeURIComponent(queueGroupId)}/advertisement/random`).then(
        response => response.data as IAdvertisement
    );
};

const getQueueGroupAdvertisement = (queueGroupId: string, advertisementId: string): Promise<IAdvertisement> => {
    return apiCall(
        'get',
        `queueGroup/${encodeURIComponent(queueGroupId)}/advertisement/${encodeURIComponent(advertisementId)}`
    ).then(response => response.data as IAdvertisement);
};

const getAllQueueGroupAdvertisements = (queueGroupId: string): Promise<IAdvertisement[]> => {
    return apiCall('get', `queueGroup/${encodeURIComponent(queueGroupId)}/advertisement/all`).then(
        response => response.data as IAdvertisement[]
    );
};

const patchQueueGroupAdvertisement = (
    queueGroupId: string,
    advertisementId: string,
    advertisement: IAdvertisement
): Promise<void> => {
    return apiCall(
        'patch',
        `queueGroup/${encodeURIComponent(queueGroupId)}/advertisement/${encodeURIComponent(advertisementId)}`,
        advertisement
    ).then(() => {});
};
const deleteQueueGroupAdvertisement = (queueGroupId: string, advertisementId: string): Promise<void> => {
    return apiCall(
        'delete',
        `queueGroup/${encodeURIComponent(queueGroupId)}/advertisement/${encodeURIComponent(advertisementId)}`
    ).then(() => {});
};

const getQueueGroupAnalytic = (queueGroupId: string, timezoneOffset: number): Promise<IAnalytic> => {
    return apiCall('get', `queueGroup/${encodeURIComponent(queueGroupId)}/analytic`, { timezoneOffset }).then(
        response => response.data as IAnalytic
    );
};

type ShiftAnalytic = {
    queueGroupId: any;
    queueId: any;
    areas: {
        id: number;
        name: string;
        queueCustomerCount: number;
        peopleCount: number;
    }[];
    total: {
        queueCustomerCount: number;
        peopleCount: number;
    };
};

const getShiftAnalytic = (): Promise<ShiftAnalytic> => {
    return apiCall('get', `queueGroup/mine/analytic/shift`).then(response => response.data as ShiftAnalytic);
};

const registerUser = (
    user: { email: string; password: string; fullName: string },
    queueGroup: {
        name: string;
        phone: string;
        type: IQueueGroupType;
        country: IQueueGroupCountry;
        address?: string;
        geometryLong?: number;
        geometryLat?: number;
        centerLong?: number;
        centerLat?: number;
    }
): Promise<IUser> => {
    return apiCall('post', 'user/register', { user, queueGroup }).then(response => response.data as IUser);
};

type LoggedInUser = {
    authTokenId: string;
    credentialTokenUuid?: string;
};

const loginUser = (email: string, password: string, rememberMe: boolean): Promise<LoggedInUser> => {
    return apiCall('post', 'user/login', { email, password, rememberMe }).then(
        response => response.data as LoggedInUser
    );
};

const loginWithCredentialToken = (credentialTokenUuid: string): Promise<LoggedInUser> => {
    return apiCall('post', 'user/login/credentialToken', {
        credentialTokenUuid,
    }).then(response => response.data as LoggedInUser);
};

const logoutUser = (): Promise<void> => {
    const credentialToken = localStorage.getItem('credentialToken');
    return apiCall('post', 'user/logout', { credentialToken }).then(() => {});
};

const whoami = (): Promise<{ email: string; fullName: string }> => {
    return apiCall('get', 'user/whoami').then(response => response.data as { email: string; fullName: string });
};

const getResolveShortUrl = (shortComponent: string): Promise<string> => {
    return apiCall('get', 'short', { shortComponent }).then(response => response.data as string);
};

export default {
    feedback: {
        post: postFeedback,
    },
    queueGroup: {
        getAll: getAllQueueGroup,
        getMine: getMyQueueGroup,
        post: postQueueGroup,
        get: getQueueGroup,
        patch: patchQueueGroup,
        area: {
            get: getQueueGroupAreas,
            patch: patchQueueGroupArea,
        },
        table: {
            get: getQueueGroupTables,
            patch: patchQueueGroupTable,
        },
        permission: {
            put: putQueueGroupPermission,
        },
        queue: {
            post: postQueueGroupQueue,
            getAll: getAllQueueGroupQueues,
            get: getQueueGroupQueue,
            delete: deleteQueueGroupQueue,
            postNext: postNextInQueueGroupQueue,
            postCallAgain: postCallAgainQueueGroupQueue,
            postPrevious: postPreviousInQueueGroupQueue,
            callCustomer: postCallCustomer,
            deleteNext: deleteNextInQueueGroupQueue,
            checkoutTable: putQueueGroupQueueTableCheckout,
            updateCustomDisplay: putQueueCustomDisplay,
            updateQueueQRid: putQueueQRId,
            customer: {
                post: postQueueGroupQueueCustomer,
                patch: patchQueueGroupQueueCustomer,
                patchNumber: patchQueueGroupQueueCustomerNumber,
                delete: deleteQueueGroupQueueCustomer,
                silentlyDelete: silentlyDeleteQueueGroupQueueCustomers,
                notifyUsingSms: postQueueGroupQueueCustomerNotifyUsingSms,
                notifyUsingPushNotification: postQueueGroupQueueCustomerNotifyUsingPushNotification,
                restore: postQueueGroupQueueCustomerRestore,
                getRemoved: getRemovedQueueCustomers,
                confirm: putQueueGroupQueueCustomerConfirmed,
                addToCart: postCustomerItemsToCart,
                getCartItems: getCustomerItemsToCart,
            },
        },
        reservation: {
            post: postQueueGroupReservation,
            getAll: getAllQueueGroupReservations,
        },
        advertisement: {
            post: postQueueGroupAdvertisement,
            get: getQueueGroupAdvertisement,
            getRandom: getRandomQueueGroupAdvertisement,
            getAll: getAllQueueGroupAdvertisements,
            patch: patchQueueGroupAdvertisement,
            delete: deleteQueueGroupAdvertisement,
        },
        analytic: {
            get: getQueueGroupAnalytic,
            getShift: getShiftAnalytic,
        },
    },
    user: {
        register: registerUser,
        login: loginUser,
        loginWithCredentialToken: loginWithCredentialToken,
        logout: logoutUser,
        whoami: whoami,
    },
    notification: {
        getParameters: getPushNotificationParameters,
    },
    shortUrl: {
        resolve: getResolveShortUrl,
    },
};
