import { loadTheme } from '@fluentui/style-utilities';

// Generate theme JSON using https://fluentuipr.z22.web.core.windows.net/heads/master/theming-designer/index.html

function initialize(): void {
    loadTheme({
        palette: {
            themePrimary: '#5bc6f9',
            themeLighterAlt: '#5bc6f9',
            themeLighter: '#5bc6f9',
            themeLight: '#5bc6f9',
            themeTertiary: '#5fcf90',
            themeSecondary: '#5fcf90',
            themeDarkAlt: '#53b4e1',
            themeDark: '#4698be',
            themeDarker: '#34708c',
            neutralLighterAlt: '#faf9f8',
            neutralLighter: '#f3f2f1',
            neutralLight: '#edebe9',
            neutralQuaternaryAlt: '#e1dfdd',
            neutralQuaternary: '#d0d0d0',
            neutralTertiaryAlt: '#c8c6c4',
            neutralTertiary: '#595959',
            neutralSecondary: '#373737',
            neutralPrimaryAlt: '#2f2f2f',
            neutralPrimary: '#000000',
            neutralDark: '#151515',
            black: '#000',
            white: '#fff',
        },
    });
}

export default {
    initialize,
};
