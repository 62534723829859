import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton } from '@mui/material';
import { useSnackbar } from 'notistack';
import { faCross, faTimes} from '@fortawesome/free-solid-svg-icons'
import Button from '../Button/button';

function SnackbarCloseButton({ snackbarKey }) {
  const { closeSnackbar } = useSnackbar();
  return (
    <IconButton onClick={() => closeSnackbar(snackbarKey)}>
      <FontAwesomeIcon color='white' size="1x" icon={faTimes} />
    </IconButton>
  );
}

export default SnackbarCloseButton;
