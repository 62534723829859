import api from '../api';
import { getItemInStorage, generateRootKey } from '../validation';
export async function onNotificationsEnabled(pushManager: PushManager, QrId?: string): Promise<void> {
    const customerId = getItemInStorage(generateRootKey('', QrId), 'customerId');
    if (!customerId) {
        console.error('Could not enable notifications - could not get customer ID');
        return;
    }
    const queueCustomerId = getItemInStorage(generateRootKey('', QrId), 'queueCustomerId');
    if (!queueCustomerId) {
        console.error('Could not enable notifications - could not get queue customer ID');
        return;
    }
    if (!window.location.hash.startsWith('#/c/')) {
        console.error('Could not enable notifications - could not get queueGroup and queue IDs');
        return;
    }
    const hashWithoutPrefix = window.location.hash.slice('#/c/'.length);
    const hashParts = hashWithoutPrefix.split('/');
    const queueGroupId = hashParts[0];
    const queueId = hashParts[1];

    const { vapidPublicKey } = await api.notification.getParameters();

    const pushSubscription = await pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: vapidPublicKey,
    });

    const pushSubscriptionJson = JSON.parse(JSON.stringify(pushSubscription));

    await api.queueGroup.queue.customer.notifyUsingPushNotification(
        queueGroupId,
        queueId,
        queueCustomerId,
        customerId,
        {
            endpoint: pushSubscriptionJson.endpoint,
            keys: {
                p256dh: pushSubscriptionJson.keys.p256dh,
                auth: pushSubscriptionJson.keys.auth,
            },
        }
    );
}
