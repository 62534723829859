// import React from "react";
import ReactDom from 'react-dom/client';
import App from './App/routes';
import './style.scss'; // eslint-disable-line import/no-unassigned-import
let root = ReactDom.createRoot(document.getElementById('root') as HTMLElement);
root.render(<App />);
// Prevent vertical overflow on iOS Safari
// https://stackoverflow.com/questions/37112218/css3-100vh-not-constant-in-mobile-browser
function appHeight() {
    const doc = document.documentElement;
    if (doc && doc.style && doc.style.setProperty) {
        const fixHeight = () => {
            document.documentElement.style.setProperty('--vh', `${window.innerHeight / 100}px`);
          };
      
          addEventListener('load', fixHeight);
          addEventListener('resize', fixHeight);
          addEventListener('orientationchange', fixHeight);
    }
}
appHeight();
// use `calc(var(--vh, 1vh) * 100)` instead of `100vh`
