import i18n, { TFunction } from 'i18next';
import { useState, useEffect } from 'react';
import languageUtil, { Context, LanguageChangeListener, globalContext, getInitialLanguage, Language } from '.';

function useLanguage(context: Context = globalContext): Language {
    const [languageState, setLanguageState] = useState<Language>((i18n.language as Language) || getInitialLanguage());
    let unmounted = false;

    useEffect(() => {
        const onChange: LanguageChangeListener = languageCode => {
            if (!unmounted) {
                setLanguageState(languageCode);
            }
        };
        languageUtil.addLanguageChangeListener(onChange, context);
        return () => {
            languageUtil.removeLanguageChangeListener(onChange, context);
            unmounted = true;
        };
    }, []);

    return languageState;
}

function useTranslationNamespace(
    namespaceName: string,
    resourcePromiseMaker: (language: Language) => Promise<any>,
    context = globalContext
): TFunction {
    const [{ t }, setT] = useState<{ t: TFunction }>({ t: (s: string) => s });
    let unmounted = false;

    const loadBundle = () => {
        if (i18n.hasResourceBundle(i18n.language, namespaceName)) {
            void i18n.loadNamespaces(namespaceName, () => {
                if (!unmounted) {
                    setT({ t: i18n.getFixedT(null, namespaceName) });
                }
            });
        } else {
            void resourcePromiseMaker(i18n.language as Language).then(jsonFile => {
                i18n.addResourceBundle(i18n.language, namespaceName, jsonFile);
                void i18n.loadNamespaces(namespaceName, () => {
                    if (!unmounted) {
                        setT({ t: i18n.getFixedT(null, namespaceName) });
                    }
                });
            });
        }
    };

    useEffect(() => {
        loadBundle();
        languageUtil.addLanguageChangeListener(loadBundle, context);
        return () => {
            languageUtil.removeLanguageChangeListener(loadBundle, context);
            unmounted = true;
        };
    }, []);

    return t;
}

const isLanguageRtl = (languageCode: Language) => languageCode === 'he';

function useIsRtl(context = globalContext): boolean {
    const [isRtl, setIsRtl] = useState<boolean>(isLanguageRtl((i18n.language as Language) || getInitialLanguage()));
    let unmounted = false;

    useEffect(() => {
        const onLanguageChange = (languageCode: Language) => {
            if (!unmounted) {
                setIsRtl(isLanguageRtl(languageCode));
            }
        };
        languageUtil.addLanguageChangeListener(onLanguageChange, context);
        return () => {
            languageUtil.removeLanguageChangeListener(onLanguageChange, context);
            unmounted = true;
        };
    }, []);

    return isRtl;
}

function useAutoSetHtmlDir(context = globalContext): void {
    const isRtl = useIsRtl(context);
    useEffect(() => {
        document.dir = isRtl ? 'rtl' : 'ltr';
        return () => {
            document.dir = 'rtl';
        };
    }, [isRtl]);
}

function useAutoSetLangProperty(context = globalContext): void {
    const lang = useLanguage(context);
    useEffect(() => {
        document.body.lang = lang;
    }, [lang]);
}

export default {
    useLanguage,
    useTranslationNamespace,
    useIsRtl,
    useAutoSetHtmlDir,
    useAutoSetLangProperty,
};
