import React, { Suspense, lazy } from 'react';
/* 
By default, React Query Devtools are only included in bundles when process.env.NODE_ENV === 'development', 
so you don't need to worry about excluding them during a production build.
https://tanstack.com/query/v4/docs/react/devtools
*/
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { HashRouter, Route, Routes } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import icons, { PreRender } from '../mui_Init/icons';
import theme from '../mui_Init/theme';
import websocket from '../ws';
import webworker from '../Modules/util/webworker';
import tawk from '../Modules/util/tawk';
import languageUtil from '../Modules/util/language';
import languageUtilReact from '../Modules/util/language/react';
import notification from '../Modules/util/notification';
import { MaterialProvider } from '../mui_Init/material';
import { styled } from '@mui/material';
import { AppHistoryProvider } from '../Modules/util/history';
import BrowserRecommendation from '../components/browserRecommendation';
import { usePopUpNotification } from '../components/ToastNotification';
import SnackbarCloseButton from '../components/closeSnackbar';

const CustomerRoute = lazy(() => import('../Modules/Features'));
const ShortenedUrlRoute = lazy(() => import('../Modules/Features/short'));
icons.initialize();
theme.initialize();
webworker.inititalize();
websocket.initialize();
languageUtil.initialize();
languageUtil.addLanguageChangeListener(newLanguage => {
    void notification.changeLanguage(newLanguage);
});

const Providers: React.FunctionComponent<{ children: any }> = (props: { children: any }) => {
    return (
        <AppHistoryProvider>
            <MaterialProvider>{props.children}</MaterialProvider>
        </AppHistoryProvider>
    );
};
function App() {
    tawk.HideWhenMobile();
    tawk.HideWhenPrint();

    languageUtilReact.useAutoSetLangProperty();
    languageUtilReact.useAutoSetHtmlDir();
    const { SnackbarProvider } = usePopUpNotification();
    const StyledSnackbarProvider = styled(SnackbarProvider)``;
    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                networkMode: 'offlineFirst', // Remove  this in production

                // cacheTime: Infinity,
            },
            mutations: {
                networkMode: 'offlineFirst',
            },
        },
    });

    return (
        <StyledSnackbarProvider
            action={snackbarKey => <SnackbarCloseButton snackbarKey={snackbarKey} />}
            autoHideDuration={4000}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            maxSnack={3}
        >
            <Suspense
                fallback={
                    <div className="fallback__loading-state">
                        <PreRender />
                    </div>
                }
            >
                <QueryClientProvider client={queryClient}>
                    <HashRouter>
                        <Providers>
                            <Routes>
                                <Route path="/" element={<CustomerRoute />} />
                                <Route path="/c/:queueGroupId/:queueId/:QrId/*" element={<CustomerRoute />} />
                                <Route path="/c/:queueGroupId/:queueId/*" element={<ShortenedUrlRoute />} />
                                <Route path="/s/:shortComponent" element={<ShortenedUrlRoute />} />
                            </Routes>
                        </Providers>
                        {/* <BrowserRecommendation /> */}
                    </HashRouter>
                    <ReactQueryDevtools initialIsOpen={false} />
                </QueryClientProvider>
            </Suspense>
        </StyledSnackbarProvider>
    );
}
export default App;
