// https://material-ui.com/guides/right-to-left/
// https://mui.com/components/pickers/
import React from 'react';
import rtlPlugin from 'stylis-plugin-rtl';
import enLocale from 'date-fns/locale/en-US';
import heLocale from 'date-fns/locale/he';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import languageUtil from '../Modules/util/language/react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

const locales = {
    en: enLocale,
    he: heLocale,
};

const cacheRtl = createCache({
    key: 'rtl',
    stylisPlugins: [rtlPlugin],
});

const cacheLtr = createCache({
    key: 'ltr',
});

type MaterialProviderProps = {
    children: React.ReactNode;
};

export const MaterialProvider: React.FunctionComponent<MaterialProviderProps> = (props: MaterialProviderProps) => {
    const language = languageUtil.useLanguage();
    const isRtl = languageUtil.useIsRtl();

    return (
        <CacheProvider value={isRtl ? cacheRtl : cacheLtr}>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={locales[language]}>
                {props.children}
            </LocalizationProvider>
        </CacheProvider>
    );
};
