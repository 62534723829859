// https://developers.google.com/web/fundamentals/push-notifications/how-push-works

import webworker from "../webworker";
import { onNotificationsEnabled } from "./util";

type Context = {
  initialized: boolean;
  notificationErrored: boolean;
};

const globalContext: Context = {
  initialized: false,
  notificationErrored: false,
};

function initContext(context: Context) {
  if (context.initialized) {
    return;
  }

  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  if (isGranted({ ...context, initialized: true })) {
    void webworker
      .getServiceWorker()
      .then((serviceWorker) =>
        onNotificationsEnabled(serviceWorker.pushManager)
      );
  }

  context.initialized = true;
}

function isSupported(context: Context = globalContext): boolean {
  initContext(context);

  return (
    "PushManager" in window &&
    "Notification" in window &&
    !context.notificationErrored
  );
}

function isGranted(context: Context = globalContext): boolean {
  initContext(context);

  return isSupported(context) && window.Notification.permission === "granted"; 
}

async function requestPermission(
  context: Context = globalContext
): Promise<boolean> {
  initContext(context);

  if (!isSupported(context)) {
    return false;
  }

  if (isGranted(context)) {
    return true;
  }

  try {
    const permission = await Notification.requestPermission();
    /*
    if (permission === "granted") {
      void webworker
        .getServiceWorker()
        .then((serviceWorker) =>
          onNotificationsEnabled(serviceWorker.pushManager)
        );
    }
    */
    return permission === "granted";
  } catch (err) {
    console.error(err);
    return false;
  }
}

async function changeLanguage(newLanguageCode: string): Promise<void> {
  const serviceWorker = await webworker.getServiceWorker();
  serviceWorker.active.postMessage(["changeLanguage", newLanguageCode]);
}

export default {
  isSupported,
  isGranted,
  requestPermission,
  changeLanguage,
};
