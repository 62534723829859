import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

type AppHistoryContext = {
    canGoBack: boolean;
    goBack: () => void;
    history: string[];
};

export const appHistoryContext = React.createContext<AppHistoryContext>({
    canGoBack: false,
    goBack: () => {},
    history: [],
});

type AppHistoryProviderProps = {
    children: any;
};
export const AppHistoryProvider: React.FunctionComponent<AppHistoryProviderProps> = (
    props: AppHistoryProviderProps
) => {
    const path = ['/login', '/register', '/'];
    const [appHistory, setAppHistory] = useState<string[]>([]);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (appHistory.length === 0 && !path.includes(location.pathname)) {
            setAppHistory([location.pathname]);
            return;
        }
        if (appHistory[appHistory.length - 1] === location.pathname && !path.includes(location.pathname)) {
            return;
        }
        if (
            appHistory.length >= 2 &&
            appHistory[appHistory.length - 2] === location.pathname &&
            !path.includes(location.pathname)
        ) {
            setAppHistory(prevAppHistory => {
                return prevAppHistory.slice(0, -1);
            });
            return;
        }
        if (!path.includes(location.pathname)) {
            setAppHistory(prevAppHistory => {
                return [...prevAppHistory, location.pathname];
            });
        }
    }, [location]);

    const context: AppHistoryContext = {
        canGoBack: appHistory.length > 1,
        goBack: () => {
            if (appHistory.length > 1 && !path.includes(location.pathname)) {
                navigate(-1);
            }
        },
        history: appHistory,
    };

    return <appHistoryContext.Provider value={context}>{props.children}</appHistoryContext.Provider>;
};
