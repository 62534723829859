import { SendMessageType, ReceiveMessageType } from "./types";
//@ts-ignore
// import MyWorker from "./worker/webWorker";
import MyWorker from "worker-loader!./worker/webWorker"; // eslint-disable-line import/no-unresolved
//@ts-ignore
import MyServiceWorker from "service-worker-loader!./worker/serviceWorker"; // eslint-disable-line import/no-unresolved
declare const register: ServiceWorkerRegistration;
function isSupported() {
  return !!window.Worker;
}

type MessageListener = (data: any[]) => void;

type Context = {
  worker: MyWorker;
  serviceWorkerPromise: Promise<ServiceWorkerRegistration>;
  messageListeners: Record<ReceiveMessageType, MessageListener[]>;
};

const globalContext: Context = {
  worker: null,
  serviceWorkerPromise: null,
  messageListeners: {
    error: [([err]) => console.error("Error from WebWorker", err)],
    wsMessage: [],
    notificationAction: [],
  },
};

function inititalize(context: Context = globalContext): void {
  if (!isSupported()) {
    return;
  }

  context.worker = new Worker(new URL('./worker/webWorker', import.meta.url), { type: 'module' });
  context.worker.onmessage = (e) => {
    const type = e.data[0] as ReceiveMessageType;
    const data = e.data.slice(1);
    context.messageListeners[type].forEach((listener) => listener(data));
  };
  console.log('Web worker initialized:', context.worker);

 
  context.serviceWorkerPromise = MyServiceWorker({ scope: "/" });
}

function sendMessage(
  type: SendMessageType,
  data: any[],
  context: Context = globalContext
): void {
  if (!isSupported() || !context.worker) {
    return;
  }

  context.worker.postMessage([type, ...data]);
}

function addMessageListener(
  type: ReceiveMessageType,
  listener: MessageListener,
  context: Context = globalContext
): void {
  if (!context.messageListeners[type]) {
    context.messageListeners[type] = [];
  }
  context.messageListeners[type].push(listener);
}

function getServiceWorker(
  context: Context = globalContext
): Promise<ServiceWorkerRegistration> {
  return context.serviceWorkerPromise;
}

export default {
  inititalize,
  sendMessage,
  addMessageListener,
  getServiceWorker,
};
