import i18n, { TFunction } from 'i18next';

const supportedLanguages = ['en', 'he'] as const;
export type Language = typeof supportedLanguages[number];

export function getInitialLanguage(): Language {
    const defaultLanguage = 'he';

    try {
        const storedLanguagePreference =
            window && window.localStorage && (window.localStorage.getItem('preferredLanguage') as Language);
        if (storedLanguagePreference && supportedLanguages.includes(storedLanguagePreference)) {
            return storedLanguagePreference;
        }

        const navigatorLanguage = navigator && navigator.language;
        const userPreferredLanguage = navigatorLanguage && (navigatorLanguage.split('-')[0].toLowerCase() as Language);
        if (userPreferredLanguage && supportedLanguages.includes(userPreferredLanguage)) {
            return userPreferredLanguage;
        }
    } catch (_e) {
        /* Do nothing */
    }

    return defaultLanguage;
}

export type LanguageChangeListener = (languageCode: Language, t: TFunction) => void;

export type Context = {
    languageChangeListeners: LanguageChangeListener[];
};

export const globalContext: Context = {
    languageChangeListeners: [],
};

async function initialize(): Promise<void> {
    await i18n.init({
        lng: getInitialLanguage(),
        resources: {},
    });
}

/**
 *
 * @param languageCode ISO 639-1 language code https://en.wikipedia.org/wiki/List_of_ISO_639-1_codes
 */
async function setLanguage(languageCode: Language, context: Context = globalContext): Promise<void> {
    const t = await i18n.changeLanguage(languageCode);
    if (window && window.localStorage) {
        window.localStorage.setItem('preferredLanguage', languageCode);
    }
    context.languageChangeListeners.forEach(listener => listener(languageCode, t));
}

function getLanguage(): Language {
    return i18n.language as Language;
}

function getDirection(): 'rtl' | 'ltr' {
    const isRtl = (languageCode: Language) => languageCode === 'he';
    return isRtl(getLanguage()) ? 'rtl' : 'ltr';
}

async function getStaticTranslationNamespace(namespaceName: string, jsonFile: Record<string, any>): Promise<TFunction> {
    if (!i18n.hasResourceBundle(i18n.language, namespaceName)) {
        i18n.addResourceBundle(i18n.language, namespaceName, jsonFile);
    }
    await i18n.loadNamespaces(namespaceName);
    return i18n.getFixedT(null, namespaceName);
}

function addLanguageChangeListener(listener: LanguageChangeListener, context = globalContext): void {
    context.languageChangeListeners.push(listener);
}

function removeLanguageChangeListener(listener: LanguageChangeListener, context = globalContext): void {
    const index = context.languageChangeListeners.findIndex(l => l === listener);
    if (index === -1) {
        return;
    }
    context.languageChangeListeners.splice(index, 1);
}

export default {
    initialize,
    setLanguage,
    getLanguage,
    getDirection,
    getStaticTranslationNamespace,
    addLanguageChangeListener,
    removeLanguageChangeListener,
};
